import * as React from "react"
import RowNav from "./nav/rowNav"

const Footer = () => (
    <footer
        className="mt-8 w-full py-4 border-t-2"
    >
        <div className="container">
            <RowNav />
            <div className="copyright">
                © {new Date().getFullYear()}, Built by me using Gatsby :)
                {` `}
                <a
                    href="https://gitlab.com/fleetfoot/jeff-temes-portfolio-v2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    source code here
                </a>
            </div>
        </div>
    </footer>
)

export default Footer
