import * as React from "react"
import { Link } from "gatsby"

import "./nav.css"

const Nav = () => (
    <nav className="main-nav flex flex-col items-end">
        <Link to="/" activeClassName="active" className="main-nav__item">
            Portfolio
        </Link>
        <Link to="/about" activeClassName="active" className="main-nav__item">
            About
        </Link>
        <Link to="/contact" activeClassName="active" className="main-nav__item">
            Contact
        </Link>
    </nav>
)

export default Nav
