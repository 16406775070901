/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

console.log('\r\n\r\n   _       __  __            \r\n  (_) ___ / _|/ _|           \r\n  | |/ _ \\ |_| |_            \r\n  | |  __/  _|  _|           \r\n _/ |\\___|_| |_|             \r\n|__/                         \r\n _                           \r\n| |_ ___ _ __ ___   ___  ___ \r\n| __/ _ \\ \'_ ` _ \\ / _ \\/ __|\r\n| ||  __/ | | | | |  __/\\__ \\\r\n \\__\\___|_| |_| |_|\\___||___/\r\n                             \r\n     _       _               \r\n  __| | ___ | |_             \r\n / _` |/ _ \\| __|            \r\n| (_| | (_) | |_             \r\n \\__,_|\\___/ \\__|            \r\n                             \r\n                             \r\n  ___ ___  _ __ ___          \r\n / __/ _ \\| \'_ ` _ \\         \r\n| (_| (_) | | | | | |        \r\n \\___\\___/|_| |_| |_|        \r\n                             \r\n\r\n')

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteDescription={data.site.siteMetadata.description}
      />

      <main className="flex-1">
        <div className="container">
          {children}
        </div>
      </main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
