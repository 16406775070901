import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Nav from "./nav/nav"

const Header = ({ siteTitle, siteDescription }) => (
  <header
    className="mx-auto pt-4 mt-0 mb-14 w-full"
  >

    <div className="container grid"
      style={{ gridTemplateColumns: `3fr 1fr`, }}
    >
      <div className="pr-2">
        <Link
          to="/"
          className="no-underline"
        >
          <h1
            className="m-0 px-3 py-0.5 bg-black text-white hover:text-gold transition-all"
          >
            {siteTitle}
          </h1>
        </Link>
        <p className="px-3 text-black mt-1 text-2xl font-bold">
          {siteDescription}
        </p>
      </div>

      <Nav />
    </div>
  </header >
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
